<template>
    <div class="payment">
        <!-- 表单弹窗 -->
        <div class="from">
            <el-dialog title="支付详情" :visible.sync="payDialog" :closeOnClickModal='false' width="600px" @close="closeDialog()">
                <el-form style="margin-top:25px">

                    <el-form-item label="" v-if="pay_info.payment_method !=2">
                        <div class="kuai">
                            <span class="xing">* </span>
                            支付方式:
                        </div>
                        <template v-if="pay_info?pay_info.trade_type='NATIVE':''">
                            微信支付
                        </template>
                        <template v-if="pay_info?pay_info.trade_type!='NATIVE':''">
                            手机端支付
                        </template>
                    </el-form-item>

                    <el-form-item label="">
                        <div class="kuai">
                            <span class="xing">* </span>
                            支付金额:
                        </div>
                        <template>
                            {{pay_info.amount_total}}
                        </template>
                    </el-form-item>
                    <el-form-item label="">
                        <div class="kuai">
                            <span class="xing">* </span>
                            完成时间:
                        </div>
                        <template>
                            {{dateFtt("yyyy-MM-dd-hh:mm", new Date(pay_info.success_time))}}
                        </template>
                    </el-form-item>
                    <el-form-item label="" v-if="teacherList.payment_method !=2">
                        <div class="kuai">
                            <span class="xing">* </span>
                            支付单号:
                        </div>
                        <template>
                            {{pay_info.out_trade_no}}
                        </template>
                    </el-form-item>

                    <el-form-item label="" v-if="pay_info.payment_method ==2">
                        <div class="kuai">
                            <span class="xing">* </span>
                            支付方式:
                        </div>
                        <template>
                            {{pay_info.trade_type}}
                        </template>
                    </el-form-item>
                </el-form>
                <!-- <div slot="footer" class="dialog-footer"> -->
                <!-- <el-button @click="closeDialog" class="success button">取 消</el-button> -->
                <!-- <el-button type="danger" style="padding:10px;width:85px" class="tijiao primary button" @click="onrefund(teacherList)"> 退款</el-button> -->
                <!-- <el-button type="primary" @click="tijiao" class="tijiao primary button" v-if="teacherList.refund!=null">提 交</el-button> -->
                <!-- </div> -->
            </el-dialog>
            <refund ref="refund"></refund>
        </div>
    </div>
</template>

<script>
import refund from "./refund.vue";
export default {
    data() {
        return {
            token: JSON.parse(localStorage.getItem("TZ-USER")).admin_token,
            payDialog: false, //操控弹窗展示
            teacherList: [], //配音师列表
            order_id: "", //订单id
            pay_info: "",
        };
    },
    mounted() {},
    components: {
        refund,
    },
    methods: {
        // 点击退款
        // onrefund(data) {
        // this.$refs.refund.onyunyingtan(data);
        // if (data.refund == null) {
        //     this.$message({ message: "该订单不可以退款", type: "warning" });
        // } else {
        //     this.$refs.refund.onyunyingtan(data);
        // }
        // },
        onpayment(id) {
            this.payDialog = true;
            // this.order_id=id;
            // // this.getDubOrderMixerByLevel();
            var param = {
                order_id: id,
            };
            this.$service.post(this.$api.getVideoOrderPayInfo, param, (res) => {
                if (res.code == "200") {
                    this.pay_info = res.data;
                }
            });
        },
        parent() {
            this.payDialog = false;
            this.$parent.ongetInternalOrderList();
        },
        // getDubOrderMixerByLevel() {
        // this.$service.post(this.$api.getMixerLabelList, "", (res) => {
        //     if (res.code == "200") {
        //         this.checkList = res.data;
        //     }
        // });
        // },

        closeDialog() {
            // this.dubber="";
            this.payDialog = false;
        },
        // 提交编辑
        tijiao() {
            // var param = {
            //    order_id:this.order_id,
            //    mixer_id:this.dubber
            // }
            // if (param.mixer_id == "") {
            //     this.$message({ message: "请选择配音师", type: "warning" });
            //     return;
            // }
            // this.$service.post(this.$api.alterDubOrderMixer, param, (res) => {
            //     if (res.code == "200") {
            //         this.$message({
            //             message: "修改成功",
            //             type: "success",
            //         });
            //         this.yunyingtan = false;
            //         this.dubber="";
            //         this.$parent.ongetInternalOrderList();
            //     }
            // });
        },
    },
};
</script>

<style lang="scss" scoped>
.payment {
    .chuangjian {
        // height: 40px;
        line-height: 40px;
    }
    .xing {
        color: red;
        margin-right: 6px;
    }
    .kuai {
        width: 200px;
        text-align: center;
        display: inline-block;
        font-weight: bold;
    }
    .tijiao {
        margin-left: 40px;
    }
    // .button {
    //     width: 90px;
    //     color: #ffffff;
    // }
    .img {
        width: 148px;
        height: 148px;
        margin-right: 20px;
    }
    //
}
</style>
<style lang="scss">
.payment {
    .upload-demo {
        display: inline-block;
    }
    .el-upload-dragger {
        height: 150px;
    }
    .el-upload__tip {
        width: 250px;
    }
    .el-dialog__header {
        background: #0075f7;
    }
    .el-dialog__title {
        color: #ffffff;
    }
    .el-form-item__content {
        display: flex;
    }
    .el-radio {
        line-height: 40px;
    }
    .right {
        // display: flex;
    }
}
</style>