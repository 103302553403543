<template>
    <div class="CompileRun">
        <!-- 表单弹窗 -->
        <div class="from">
            <el-dialog title="退款" :visible.sync="refundDialog" :closeOnClickModal='false' width="500px" @close="closeDialog()">
                <el-form style="margin-top:25px">
                    <div class="searchdan">
                        <div class="title" style="margin-right: 0px !important;">退款原因：</div>
                        <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="reason">
                        </el-input>
                    </div>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="closeDialog" class="success button">取 消</el-button>
                    <el-button type="primary" @click="tijiao" class="tijiao primary button">确定退款</el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            token: JSON.parse(localStorage.getItem("TZ-USER")).admin_token,
            refundDialog: false, //操控弹窗展示
            // teacherList: [], //配音师列表
            dubber: "", //配音师
            order_id: "", //订单id
            reason: "",
        };
    },
    mounted() {},
    methods: {
        onyunyingtan(data) {
            this.refundDialog = true;
            this.order_id = data.order_id;
        },
        closeDialog() {
            this.dubber = "";
            this.refundDialog = false;
        },
        // 提交编辑
        tijiao() {
            var param = {
                order_id: this.order_id,
                reason: this.reason,
            };
            if (param.reason == "") {
                this.$message({ message: "请输入退款原因", type: "warning" });
                return;
            }
            this.$service.post(this.$api.dubRefund, param, (res) => {
                if (res.code == "200") {
                    this.refundDialog = false;
                    this.reason = "";
                    this.$message({ message: "正在退款···", type: "warning" });
                    this.$parent.parent();
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.CompileRun {
    .chuangjian {
        // height: 40px;
        line-height: 40px;
    }
    .xing {
        color: red;
        margin-right: 6px;
    }
    .kuai {
        width: 140px;
        display: inline-block;
    }
    .tijiao {
        margin-left: 40px;
    }
    // .button {
    //     width: 90px;
    //     color: #ffffff;
    // }
    .img {
        width: 148px;
        height: 148px;
        margin-right: 20px;
    }
    //
}
</style>
<style lang="scss">
.CompileRun {
    .upload-demo {
        display: inline-block;
    }
    .el-upload-dragger {
        height: 150px;
    }
    .el-upload__tip {
        width: 250px;
    }
    .el-dialog__header {
        background: #0075f7;
    }
    .el-dialog__title {
        color: #ffffff;
    }
    .el-form-item__content {
        display: flex;
    }
    .el-radio {
        line-height: 40px;
    }
}
</style>