<template>
    <div class="CompileRun">
        <!-- 表单弹窗 -->
        <div class="from">
            <el-dialog title="上传配音" :visible.sync="UpDialog " :closeOnClickModal='false' width="500px" @close="closeDialog()">
                <el-form style="margin-top:25px">
                    <div class="padd20 borbut20" style="border-bottom: 0px solid #f2f7fa;">
                        <div class="topSearchSou">
                            <div class="searchdan">
                                <div class="title" style="margin-right: 0px !important;"><span class="xing">* </span>产品名称：</div>
                                <el-input v-model="product_name" placeholder="请输入产品名称" style="margin-left:20px"></el-input>
                            </div>
                        </div>
                    </div>
                    <template>
                        <el-upload class="upload-demo" ref='upload' :action="$api.uploadFile + '?token=' + token" accept=".mp4,.m4a,.mp3," :on-preview="handlePreview" :on-remove="handleRemove" :before-remove="beforeRemove" multiple :limit="1" :on-exceed="handleExceed" :on-success="onSuccessbud" :file-list="fileList">
                            <el-button size="small" type="primary" style="margin-left:180px;">点击上传</el-button>
                        </el-upload>
                    </template>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="closeDialog" class="success button">取 消</el-button>
                    <el-button type="primary" @click="tijiao" class="tijiao primary button">上 传</el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            token: JSON.parse(localStorage.getItem("TZ-USER")).admin_token,
            UpDialog: false, //操控弹窗展示
            teacherList: [], //配音师列表
            dubber: "", //配音师
            order_id: "", //订单id
            fileList: [],
            introduce: "",
            title: "",
            mixer_id: "",
            product_name: "",
        };
    },
    mounted() {},
    methods: {
        //配音上传成功
        onSuccessbud(res, file, fileList) {
            this.introduce = res.data.file_id;
            this.title = file.name;
        },
        handleRemove(file, fileList) {
            // console.log(file, fileList);
        },
        handlePreview(file) {
            // console.log(file);
        },
        handleExceed(files, fileList) {
            this.$message.warning(
                `当前限制选择 1 个文件，本次选择了 ${
                    files.length
                } 个文件，共选择了 ${files.length + fileList.length} 个文件`
            );
        },
        beforeRemove(file, fileList) {
            return this.$confirm(`确定移除 ${file.name}？`);
        },
        onUploading(id, data) {
            this.UpDialog = true;
            this.mixer_id = data.mixer_id;
            this.order_id = data.id;
            // this.getDubOrderMixerByLevel();
            // var param = {
            //     order_id: id,
            // };
            // this.$service.post(
            //     this.$api.getDubOrderMixerByLevel,
            //     param,
            //     (res) => {
            //         if (res.code == "200") {
            //             this.teacherList = res.data.data;
            //         }
            //     }
            // );
        },
        onShopCheChange(val) {
            this.ShopCheckList = val;
        },

        closeDialog() {
            this.$refs.upload.clearFiles();
            this.mixer_id = "";
            this.order_id = "";
            this.introduce = "";
            this.product_name = "";
            this.UpDialog = false;
        },
        // 提交编辑
        tijiao() {
            var param = {
                order_id: this.order_id,
                mixer_id: this.mixer_id,
                file_id: this.introduce,
                title: this.title,
                product_name: this.product_name,
            };
            this.$service.post(this.$api.dubRecordUploadFile, param, (res) => {
                if (res.code == "200") {
                    this.$message({
                        message: "上传配音成功",
                        type: "success",
                    });
                    this.closeDialog();
                    this.$parent.ongetInternalOrderList();
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.CompileRun {
    .chuangjian {
        // height: 40px;
        line-height: 40px;
    }
    .xing {
        color: red;
        margin-right: 6px;
    }
    .kuai {
        width: 140px;
        margin-left: 60px;
        // display: inline-block;
    }
    .tijiao {
        margin-left: 40px;
    }
    // .button {
    //     width: 90px;
    //     color: #ffffff;
    // }
    .img {
        width: 148px;
        height: 148px;
        margin-right: 20px;
    }
    //
}
</style>
<style lang="scss">
.CompileRun {
    .upload-demo {
        display: inline-block;
    }
    .el-upload-dragger {
        height: 150px;
    }
    .el-upload__tip {
        width: 250px;
    }
    .el-dialog__header {
        background: #0075f7;
    }
    .el-dialog__title {
        color: #ffffff;
    }
    .el-form-item__content {
        display: flex;
    }
    .el-radio {
        line-height: 40px;
    }
    .el-upload-list__item-name {
        margin-left: 80px;
    }
}
</style> 