<template>
    <div class="shooting">
        <inquiry ref="inquiry"></inquiry>
        <div class="padd20">
            <div class="duoshao">
                <div style="margin-left:15px">视频订单列表 </div>
                <el-button size="primary" slot="reference" type="primary" @click="derive()" style="margin-left:25px">批量导出</el-button>
                <div class="lvtotal">总条数：{{lvtotal}} 条
                    <span style="margin-left:20px">每页显示:</span>
                    <el-select v-model="limit" placeholder="10" @change="selectTrigger()" style="width:80px; margin:0px 15px">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    条
                    <div class="lvtotal riri">
                    </div>
                </div>
            </div>
            <div v-if="tableData !=''">
                <div class="list" v-for='(item,index) in tableData' :key="item.index" :id="index">
                    <div class="title tits" :style="'background:' + (item.order_status==1 ? '#e5f3f7' : item.order_status==2 ? '#e6f1e1' : item.order_status==3 ? '#f7f0e5' : '')">
                        <div>
                            <input type="checkbox" :value="item.id" v-model="postIdList" style="margin-right:25px; margin-top:8px">
                            <span style="width:100px;display: inline-block;">ID：{{item.id}}</span>
                            <span style="width:240px;display: inline-block;">订单号：{{item.order_num}}</span>
                            <span style="width:240px;display: inline-block;  word-break: break-all; text-overflow: ellipsis;display: -webkit-box;cursor: pointer;-webkit-box-orient: vertical;-webkit-line-clamp: 1 !important;overflow: hidden;">用户名称：<img :src="item.server_wechat?item.server_wechat.wechat_head:''" alt="" class="img"> {{item.server_wechat ? Base64.decode(item.server_wechat.wechat_name):'--'}}</span>
                            <span style="width:240px;display: inline-block;">用户手机号：{{item.server_user ? item.server_user.account : '暂无'}}</span>
                            <span style="width:200px;display: inline-block;" v-if="item.mixer">配音师名称：{{item.mixer.mixer ? item.mixer.mixer : '- -'}}</span>
                            <!-- <span style="width:200px;display: inline-block;"> {{item.tab==1 ? '视频小程序混剪' : ''}}
                                {{item.tab==2 ? '视频小程序素材' : ''}}
                                {{item.tab==3 ? '视频实拍' : ''}}</span> -->
                        </div>
                        <div></div>
                        <div>
                            <el-button type="primary" @click="payment(item.id)" style="margin-left:20px;line-height: 10px;">支付详情</el-button>
                            <!-- <el-button size="primary" slot="reference" type="primary" @click="follow(item.id,item)" style="margin-left:0px">查看详情</el-button> -->
                        </div>
                    </div>

                    <div class="center">
                        <div class="one">
                            <div class="flex">
                                <div style="width:100px">反馈信息</div>

                                <p>{{item.audit_status ? item.audit_status : '- -'}}</p>
                            </div>
                            <div class="flex">
                                <div style="width:100px">价格</div>
                                <p>{{item.cost ? item.cost : '- -'}}</p>
                            </div>
                            <div class="flex">
                                <div style="width:100px">剪辑：</div>
                                <p> {{item.tab==1 ? '视频小程序混剪' : ''}}
                                    {{item.tab==2 ? '视频小程序素材' : ''}}
                                    {{item.tab==3 ? '视频实拍' : ''}}</p>
                            </div>
                        </div>
                        <!--  -->
                        <div class="one">
                            <div class="flex">
                                <div style="width:140px">是否体现价格</div>
                                <p style="width:85px">{{item.is_priceandactivity==1 ? '是' : '否'}}</p>
                            </div>
                            <div class="flex">
                                <div style="width:140px">是否寄回样品</div>
                                <p style="width:85px">{{item.is_mail==1 ? '是' : '否'}}</p>
                            </div>
                            <!-- <div class="flex">
                            <div>视频案例</div>
                            <p @click="onvideo(item.video_case)" class="chaoxian" v-if="item.video_case!=null">
                                <a href="">点击查看</a>
                            </p> 
                            <p v-if="item.video_case==null">- -</p>
                        </div> -->
                            <div class="flex">
                                <div style="width:140px">产品类型</div>
                                <p style="cursor: pointer; width:85px;margin-left:-20px">
                                    <span>
                                        <el-tag effect="dark">{{item.video_classify.classify_name}}</el-tag>
                                    </span>
                                </p>
                            </div>

                        </div>
                        <!--  -->
                        <div class="one">
                            <div class="flex">
                                <div style="width:160px">是否遮盖商标</div>
                                <p style="width:85px">{{item.is_brand}}</p>
                            </div>
                            <div class="flex">
                                <div style="width:160px">是否添加水印</div>
                                <p style="width:85px">{{item.is_watermark ? item.is_watermark : '- -'}}</p>
                            </div>
                            <div class="flex">
                                <div style="width:160px">是否需要发送邮箱</div>
                                <p style="width:85px">{{item.email==1 ? '是' : '否'}}</p>
                                <!-- <p>{{ item.email == 1?item.email:item.email == 2 ? '是':'暂无数据'}}</p> -->
                            </div>

                            <!-- <div class="flex">
                            <div>视频案例</div>
                            <p @click="onvideo(item.video_case)" class="chaoxian" v-if="item.video_case!=null">
                                <a href="">点击查看</a>
                            </p>
                            <p v-if="item.video_case==null">- -</p>
                        </div> -->

                        </div>
                        <!--  -->
                        <div class="one">
                            <div class="flex">
                                <div style="width:100px">卖点</div>
                                <el-tooltip class="item" effect="dark" popper-class="testtooltip" :content="item.selling_points" placement="left-start">
                                    <p class="xians">{{item.selling_points?item.selling_points:"- -"}}</p>
                                </el-tooltip>

                            </div>
                            <div class="flex">
                                <div style="width:100px">要求</div>
                                <el-tooltip class="item" effect="dark" popper-class="testtooltip" :content="item.ask" placement="left-start">
                                    <p class="xians">{{item.ask?item.ask:"- -"}}</p>
                                </el-tooltip>
                            </div>
                            <div class="flex">
                                <div style="width:100px">退款详情</div>
                                <el-tooltip class="item" effect="dark" popper-class="testtooltip" :content="item.refund_info" placement="left-start">
                                    <p class="xians">{{item.refund_info?item.refund_info:"- -"}}</p>
                                </el-tooltip>
                            </div>

                        </div>
                        <!--  -->
                        <div class="one">
                            <div class="flex">
                                <div style="width:112px">价格活动详情</div>
                                <el-tooltip class="item" effect="dark" popper-class="testtooltip" :content="item.priceandactivity_info" placement="left-start">
                                    <p class="xians">{{item.priceandactivity_info?item.priceandactivity_info:"- -"}}</p>
                                </el-tooltip>
                            </div>
                            <div class="flex">
                                <div style="width:112px">寄回地址</div>
                                <el-tooltip class="item" effect="dark" popper-class="testtooltip" :content="item.address" placement="left-start">
                                    <p class="xians">{{item.address ? item.address : '- -'}}</p>
                                </el-tooltip>

                            </div>
                            <div class="flex">
                                <div style="width:112px">产品链接</div>
                                <p v-if="item.product_link" @click="onvideo(item.product_link)" class="chaoxian">
                                    <a href="">点击查看</a>
                                </p>
                                <p v-else> -- </p>
                            </div>

                        </div>
                        <!--  -->
                        <div class="one">

                            <div class="flex">
                                <div style="width:90px">付款时间</div>
                                <p>{{item.shoot_type ? item.shoot_type : '- -'}}</p>
                            </div>
                            <div class="flex">
                                <div style="width:90px">订单状态</div>
                                <p style="cursor: pointer;"> <span v-if="item.status==1">
                                        <el-tag type="warning" effect="dark">未付款</el-tag>
                                    </span>
                                    <span v-else-if="item.status==2">
                                        <el-tag effect="dark">已付款</el-tag>
                                    </span>
                                    <span v-else-if="item.status==3">
                                        <el-tag effect="dark">拍摄中</el-tag>
                                    </span>
                                    <span v-else-if="item.status==4">
                                        <el-tag type="success" effect="dark">已完成</el-tag>
                                    </span>
                                    <span v-else-if="item.status==5">
                                        <el-tag type="danger" effect="dark">已退款</el-tag>
                                    </span>
                                </p>
                            </div>
                            <div class="flex">
                                <div style="width:90px">秒数</div>
                                <p>{{item.second_num}}</p>
                            </div>
                            <!-- <div class="flex">
                            <div>支付方式</div>
                            <p style="cursor: pointer;">
                                <span v-if="item.payment_method==1">
                                    <el-tag type="success" effect="dark">微信</el-tag>
                                </span>
                                <span v-else>
                                    <el-tag type="warning" effect="dark">积分</el-tag>
                                </span>
                            </p>
                        </div> -->
                            <!-- <div class="flex">
                            <div style="width:210px">参考视频链接</div>
                        
                        </div> -->
                        </div>
                        <!--  -->
                        <div class="one" style="border-right: 0px solid #ccc;">
                            <div class="flex">
                                <div>实拍占比</div>
                                <p>{{item.will_share}}</p>
                            </div>
                            <div class="flex">
                                <div>横版竖版</div>
                                <p>{{item.model==1 ? '竖版' : '横版'}}</p>
                            </div>
                            <div class="flex">
                                <div>版型格式</div>
                                <el-tooltip class="item" effect="dark" popper-class="testtooltip" :content="item.model_format" placement="left-start">
                                    <p class="xians" style="width:100px !important">{{item.model_format ? item.model_format : '- -'}}</p>
                                </el-tooltip>
                            </div>
                            <!-- <div class="flex">
                            <div>支付方式</div>
                            <p style="cursor: pointer;">
                                <span v-if="item.payment_method==1">
                                    <el-tag type="success" effect="dark">微信</el-tag>
                                </span>
                                <span v-else>
                                    <el-tag type="warning" effect="dark">积分</el-tag>
                                </span>
                            </p>
                        </div> -->
                            <!-- <div class="flex">
                            <div style="width:210px">参考视频链接</div>
                            <p @click="onvideo(item.video_link.url_link)" class="chaoxian">
                                <a href="">点击查看</a>
                            </p>
                        </div> -->
                        </div>
                        <!-- <div class="auto"> -->
                        <!-- <el-button type="primary" @click="amend(item.id)">修改配音师</el-button>
                        <el-button type="primary" @click="payment(item.id)" style="margin-left:20px; margin-top:25px">查看详情</el-button> -->
                        <!-- <el-button type="warning" @click="tickling(item.id,item)">配音反馈</el-button> -->
                        <!-- <el-button type="success" style="margin-left:20px; margin-top:25px">上传配音</el-button> -->
                        <!-- </div> -->
                    </div>
                    <div class="wenan">
                        <div class="flex">
                            <div>
                                <span class="peiyin">
                                    配音文案：
                                    <el-button type="success" class="copy" @click="doCopy(item.content)" style=" position: absolute;bottom: -10px; right: 20px;">一键复制</el-button>
                                </span>
                                <!-- <el-tooltip class="item" effect="dark" popper-class="testtooltip" :content="item.content" placement="top-start"> -->
                                <p class="content">{{item.content ? item.content : '- -'}}</p>
                                <!-- </el-tooltip> -->
                            </div>

                        </div>
                    </div>
                </div>

            </div>

            <div v-if="tableData ==''" class="zanwu">
                暂无数据
            </div>
        </div>
        <!-- 分页 -->
        <div class="fenye">
            <el-pagination class="lv-page" :page-size="limit" :current-page.sync="currentPage" :pager-count="11" @current-change="DialogCurrentChange" background layout="prev, pager, next, jumper" :total="lvtotal" hide-on-single-page></el-pagination>
        </div>
        <amend ref="amend"></amend>
        <payment ref="payment"></payment>
        <tickling ref="tickling"></tickling>
        <uploading ref="uploading"></uploading>
    </div>
</template>

<script>
import amend from "./amend.vue";
import payment from "./payment.vue";
import inquiry from "./inquiry.vue";
import tickling from "./tickling.vue";
import Uploading from "./uploading.vue";
export default {
    data() {
        return {
            paramData: {},
            tableData: [],
            lvtotal: null,
            limit: 10, //一页多少条
            currentPage: 1, //当前页
            message: "",
            postIdList: [], //多选
            options: [
                {
                    value: 25,
                    label: "25",
                },
                {
                    value: 50,
                    label: "50",
                },
                {
                    value: 100,
                    label: "100",
                },
            ],
        };
    },
    methods: {
        //批量导出
        derive() {
            var paramData = this.paramData;
            delete paramData.page;
            // if (
            //     paramData.order_id == undefined &&
            //     paramData.mixer_id == undefined &&
            //     paramData.record_status == undefined &&
            //     paramData.status == undefined &&
            //     paramData.beginTime == undefined &&
            //     paramData.endTimel == undefined &&
            //     this.postIdList == []
            // ) {
            //     this.$message({ message: "请搜索导出条件", type: "warning" });
            //     return;
            // }
            // window.open(
            //     this.$api.dubOrderBatchExport +
            //         "?order_id=" +
            //         (paramData.order_id ? paramData.order_id : "") +
            //         "&mixer_id=" +
            //         (paramData.mixer_id ? paramData.mixer_id : "") +
            //         "&record_status=" +
            //         (paramData.record_status ? paramData.record_status : "") +
            //         "&status=" +
            //         (paramData.status ? paramData.status : "") +
            //         "&beginTime=" +
            //         (paramData.beginTime ? paramData.beginTime : "") +
            //         "&endTimel=" +
            //         (paramData.endTimel ? paramData.endTimel : "") +
            //         "&arr_id=" +
            //         (this.postIdList ? this.postIdList : "") +
            //         "&token=" +
            //         JSON.parse(localStorage.getItem("TZ-USER")).admin_token
            // );
        },
        //反馈
        // tickling(id, data) {
        //     if (data.record_status == 1) {
        //         this.$refs.tickling.ontickling(id);
        //     } else {
        //         this.$refs.uploading.onUploading(id, data);
        //     }
        // },
        //支付详情
        payment(id) {
            this.$refs.payment.onpayment(id);
        },
        //修改配音师
        amend(id) {
            this.$refs.amend.onyunyingtan(id);
        },
        //一键复制
        doCopy: function (val) {
            this.message = val;
            // console.log(val)
            var that = this;
            // this.dataProcessing(val);

            this.$copyText(this.message).then(
                function (e) {
                    that.$message.success("复制成功");
                },
                function (e) {
                    that.$message.error("复制失败");
                }
            );
        },
        //   获取订单列表
        ongetInternalOrderList() {
            var param = this.paramData;
            param.page = this.currentPage;
            this.$service.get(this.$api.getVideoOrderList, param, (res) => {
                if (res.code == "200") {
                    this.tableData = res.data.data;
                    this.lvtotal = res.data.total;
                }
            });
        },
        //批量导出
        derive() {
            var paramData = this.paramData;
            delete paramData.page;
            console.log(this.postIdList);
            if (
                paramData.order_id == undefined &&
                paramData.mixer_id == undefined &&
                paramData.tab == undefined &&
                paramData.status == undefined &&
                paramData.beginTime == undefined &&
                paramData.endTimel == undefined &&
                this.postIdList.length == 0
            ) {
                this.$message({ message: "请搜索导出条件", type: "warning" });
                return;
            }
            window.open(
                this.$api.videoOrderBatchExport +
                    "?order_id=" +
                    (paramData.order_id ? paramData.order_id : "") +
                    "&mixer_id=" +
                    (paramData.mixer_id ? paramData.mixer_id : "") +
                    "&tab=" +
                    (paramData.tab ? paramData.tab : "") +
                    "&status=" +
                    (paramData.status ? paramData.status : "") +
                    "&beginTime=" +
                    (paramData.beginTime ? paramData.beginTime : "") +
                    "&endTimel=" +
                    (paramData.endTimel ? paramData.endTimel : "") +
                    "&arr_id=" +
                    (this.postIdList ? this.postIdList : "") +
                    "&token=" +
                    JSON.parse(localStorage.getItem("TZ-USER")).admin_token
            );
        },
        // 查看详情
        follow(id, row) {
            this.$router.push({ path: "/subproject", query: { id: id } });
        },
        //显示条数改变
        selectTrigger() {
            this.currentPage = 1;
            this.paramData.per_page = this.limit;
            this.ongetInternalOrderList();
        },
        // 分页
        DialogCurrentChange(val) {
            this.currentPage = val;
            this.$router.push({
                path: "../ErLangcha/VideoOrder",
                query: { currentPage: val },
            });
            this.ongetInternalOrderList();
        },

        //接收搜索参数
        onAllSearch(data) {
            this.paramData.beginTime = data.beginTime; //开始时间
            this.paramData.endTimel = data.endTimel; //结束时间
            this.paramData.mixer_id = data.mixer_id; //配音师id
            this.paramData.order_id = data.order_id; //订单号
            this.paramData.tab = data.tab; //客户状态
            this.paramData.status = data.status; //订单状态
            this.ongetInternalOrderList();
        },
        //查看视频
        onvideo(video) {
            window.open(video);
        },
    },

    mounted() {
        if (this.$route.query.currentPage) {
            this.currentPage = this.$route.query.currentPage * 1;
        }
        this.ongetInternalOrderList();
    },
    components: {
        amend,
        payment,
        inquiry,
        tickling,
        Uploading,
    },
};
</script>

<style lang="scss" scoped>
.shooting {
    .list {
        border: 1px solid #ccc;
        margin-bottom: 25px;
    }
    .title {
        display: flex;
        line-height: 30px;
        justify-content: space-between;
        border-bottom: 1px solid #ccc;
        margin: 0 auto;
        padding: 10px 20px;
        div {
            display: flex;
            margin-left: 25px;
            height: 30px !important;
            line-height: 30px;
        }
        // div:nth-child(2) {
        //     margin-left: 160px;
        // }
        // div:nth-child(3) {
        //     margin-left: 240px;
        // }
        // div:nth-child(4) {
        //     margin-left: 240px;
        // }
        .beizhu {
            display: flex;
            text-align: left;
            span {
                width: 50px;
            }
            div {
                margin-left: 0px;
            }
        }
    }
    .tits {
        background: #eef6fe;
    }
    .tit {
        border-bottom: 0px solid #ccc;
        border-top: 1px solid #ccc;
    }
    .center {
        display: flex;
        // margin-left: 20px;
        // margin-right: 20px;
        .one {
            // width: 430px;
            border-right: 1px solid #ccc;
            padding-top: 10px;
        }
        .flex {
            display: flex;
            line-height: 33px;
            margin-bottom: 10px;
            div {
                // width: 150px;
                text-indent: 2em;
                font-weight: bold;
            }
            p {
                width: 100px;
                text-indent: 1em;
            }
        }
        .jin p {
            width: 50%;
        }
        .flexs {
            display: flex;
            div {
                width: 147px !important;
            }
        }
    }
    .wenan {
        width: 100%;
        border-top: 1px solid #ccc;
    }
    .auto {
        margin: 0 auto;
        margin-top: 80px;
        // display: flex;
    }
    .hei {
        height: 34px;
    }
    .rig p {
        border-right: 0px !important;
    }
    .flock-load {
        max-height: 200px;
        width: 100%;
        overflow-y: auto;
        display: inline-block;
    }
    .fenye {
        text-align: right;
        margin-top: 25px;
        margin-right: 50px;
    }
    .fromkuang {
        position: relative;
    }
    // .cha {
    //     position: absolute;
    //     top: 20px;
    //     right: 25px;
    //     text-align: center;
    //     span {
    //         display: block;
    //         margin-top: 10px;
    //         cursor: pointer;
    //         color: #0075f7;
    //         font-weight: bold;
    //     }
    //     .el-button--primary {
    //         margin: 0 !important;
    //     }
    // }
    .ddv {
        line-height: 34px;
    }
    .xians {
        word-break: break-all;
        text-overflow: ellipsis;
        display: -webkit-box;
        cursor: pointer;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1 !important;
        overflow: hidden;
        width: 160px !important;
    }
    .zanwu {
        text-align: center;
        color: #909399;
    }
    .duoshao {
        line-height: 35px;
    }
    .lvtotal {
        .el-select .el-input {
            margin-left: 20px;
            margin-right: 20px;
            width: 90px !important;
        }
    }
    .imgss {
        cursor: pointer;
        margin-top: -60px;
        width: 100px;
        height: 100px;
        border: 0px;
    }
    .img {
        width: 30px;
        height: 30px;
        margin: 0px 10px;
    }
    .chaoxian {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        word-break: break-all;
    }
    .content {
        width: 85%;
        margin: 0px auto;
        padding: 10px 10px;
        line-height: 24px;
        cursor: pointer;
        margin-top: -30px;
    }
    .peiyin {
        margin-left: 20px;
        margin-top: 10px;
        text-indent: 2em;
        display: block;
        font-weight: bold;
        position: relative;
    }
}
</style>
<style lang="scss">
.shooting {
    .el-tag--dark.el-tag--danger {
        text-indent: 0 !important;
    }
    .el-tag--dark {
        text-indent: 0 !important;
    }
    .el-tag--warning {
        text-indent: 0 !important;
    }
    .el-textarea__inner {
        width: 95%;
        display: block;
        margin: 10px auto;
    }
}
.testtooltip {
    text-align: center;
    width: 300px;
}
</style>

